import { Components, ColorProfiles } from '@powdr/constants';

// Color Profiles
const {
  BASE, PRIMARY, SECONDARY, TERTIARY, QUATERNARY,
} = ColorProfiles;

export const config = {
  [Components.HEADER]: QUATERNARY,
  [Components.MAIN_NAV]: BASE,
  [Components.MEGA_NAV]: BASE,
  [Components.MOBILE_NAV]: PRIMARY,
  [Components.ECOMMERCE_BANNER]: TERTIARY,
  [Components.WEATHER_FEED]: SECONDARY,
  [Components.PROMOTIONS_FEED]: BASE,
  [Components.PROMOTIONS]: SECONDARY,
  [Components.ALERT_BAR]: TERTIARY,
  [Components.STICKY_NAV]: TERTIARY,
  [Components.TERTIARY_NAV]: BASE,
  [Components.LEVELS_MODAL]: TERTIARY,
  [Components.FOOTER]: TERTIARY,
  [Components.PARTNERS]: TERTIARY,
  [Components.COPYRIGHT]: SECONDARY,
  [Components.MODAL]: TERTIARY,
  [Components.TV]: SECONDARY,
  [Components.DOR]: BASE,
  layout: {
    headerHeight: 90,
    headerHeightMobile: 60,
    headerLogoContainerWidth: 265,
    headerFeedContainer: 300,
  },
};
