import {
  FontSize,
  FontFamily,
  TextTransformOptions,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"CenturyGothicW01"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG40,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"jellee"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.LRG12,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"CenturyGothicW01Bold"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG30,
    scaling: 1,
  },
};

const fontOverrides = {
  /* [Components.CONTENT_BLOCK_HERO]: {
    header: {
      size: `$`,
    },
  }, */
};

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
