import { ThemeProps as Prop, ColorProfiles } from '@powdr/constants';

import { componentThemesDefault } from './shared-theme-data';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#4D5859',
    [Prop.CONTENT]: '#4D5859',
    [Prop.LINK_TXT]: '#D4441C',
    [Prop.LINK_TXT_HOVER]: '#E9792C',
    [Prop.BTN_BG]: '#D4441C',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#E9792C',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#00AEBB',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#BDC6C3',
    [Prop.BTN_TXT_INACTIVE]: '#4D5859',
    [Prop.ELEMENT]: '#D4441C',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#C4C4C4',
    [Prop.ICON]: '#00818F',
    [Prop.ICON_HOVER]: '#D4441C',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#68706D',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#14CAD3',
    [Prop.LINK_TXT_HOVER]: '#E9792C',
    [Prop.BTN_BG]: '#00AEBB',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#E9792C',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#E9792C',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#BDC6C3',
    [Prop.BTN_TXT_INACTIVE]: '#4D5859',
    [Prop.ELEMENT]: '#14CAD3',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#4D5859',
    [Prop.ICON]: '#14CAD3',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#00818F',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#234C5B',
    [Prop.LINK_TXT_HOVER]: '#14CAD3',
    [Prop.BTN_BG]: '#D4441C',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#E9792C',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#D4441C',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#BDC6C3',
    [Prop.BTN_TXT_INACTIVE]: '#4D5859',
    [Prop.ELEMENT]: '#234C5B',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#126978',
    [Prop.ICON]: '#234C5B',
    [Prop.ICON_HOVER]: '#14CAD3',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#234c5b',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#14CAD3',
    [Prop.LINK_TXT_HOVER]: '#E9792C',
    [Prop.BTN_BG]: '#D4441C',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#E9792C',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#D4441C',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#BDC6C3',
    [Prop.BTN_TXT_INACTIVE]: '#4D5859',
    [Prop.ELEMENT]: '#00818F',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#116978',
    [Prop.ICON]: '#00818F',
    [Prop.ICON_HOVER]: '#14CAD3',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#D4441C',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#2F3738',
    [Prop.LINK_TXT_HOVER]: '#00818F',
    [Prop.BTN_BG]: '#2F3738',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#00818F',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#00818F',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#BDC6C3',
    [Prop.BTN_TXT_INACTIVE]: '#4D5859',
    [Prop.ELEMENT]: '#00818F',
    [Prop.ELEMENT_TXT]: '#FFFFFF',
    [Prop.BORDER]: '#586161',
    [Prop.ICON]: '#BDC6C3',
    [Prop.ICON_HOVER]: '#00818F',
  },
  ...componentThemesDefault,
};
